import { Image } from "./Image.entity";
class AccountProfile {
  constructor(data = {}) {
    this.id = data.accountId || null;
    this.username = data.username || "";
    this.fullName = data.firstName ? `${data.firstName} ${data.lastName}` : "";
    this.image =  new Image(data.image);
    this.email = data.email || "";
  }
}

export default AccountProfile;
