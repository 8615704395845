import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { Image } from "./Image.entity";

class Paragraph {
  constructor(data = {}) {
    this.id = data.id || null;
    this.text = data.text
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(JSON.parse(data.text)).contentBlocks,
            htmlToDraft(JSON.parse(data.text)).entityMap
          )
        )
      : EditorState.createEmpty();
    this.videoUrl = data.video ? data.video.videoUrl : "";
    this.imageUrl = data.imageUrl || "";
    this.image = new Image(data.image);
    this.collapse = data.collapse || false;
  }
}

class RawParagraph {
  constructor(data = {}) {
    this.id = data.id || null;
    this.text = data.text;
    this.videoUrl = data.video ? data.video.videoUrl : "";
    this.imageUrl = data.imageUrl || "";
    this.image = new Image(data.image);
    this.collapse = true;
  }
}

export { Paragraph, RawParagraph };
