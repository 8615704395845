import { Question } from "./Question.entity";
import AccountProfile from "./Profile.entity";
class Quiz {
  constructor(data = {}) {
    this.id = data.id || null;
    this.accountId = data.accountId || null;
    this.title = data.title || "";
    this.description = data.description || "";
    this.passingPercentage = data.passingPercentage || 100;
    this.activeFrom = new Date(data.activeFrom) || new Date();
    this.activeTo = new Date(data.activeTo) || new Date();
    this.eventId = data.eventId || "";
    this.quizCollapse = false;
    this.created = data.createdAt
      ? new Date(data.createdAt).toLocaleDateString()
      : "";
    this.questions = data.questions
      ? data.questions.map(q => new Question(q))
      : [];
  }
}

class StartedQuiz {
  constructor(data = {}) {
    this.id = data.id || null;
    this.title = data.title || "";
    this.description = data.description || "";
    this.passingPercentage = data.passingPercentage || 100;
    this.activeFrom = data.activeFrom || new Date();
    this.activeTo = data.activeTo || new Date();
    this.numberOfQuestions = data.numberOfQuestions || 0;
    this.nextQuestionId = data.nextQuestionId || null;
  }
}

class QuizReward {
  constructor(data ={}) {
    this.id = data.id || null;
    this.result = data.result || '';
    this.percentage = data.percentage || 0;
    this.answeredCorrectly = data.answeredCorrectly || 0;
    this.created = new Date(data.created).toLocaleDateString() || '';
    this.account = new AccountProfile(data.account);
  }
}

export { Quiz, StartedQuiz, QuizReward };
