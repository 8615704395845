import { apiService } from "./apiService";
import { EditSingleKnowledgeBase } from "./Models/Knowledge.entity";
import { Paragraph } from "./Models/Paragraph.entity";
import { Category } from "./Models/Category.entity";
import {
  KnowledgeBase,
  SingleKnowledgeBase,
  KnowledgeBaseSearch
} from "./Models/Knowledge.entity";

export const createKnowledgeBase = async payload => {
  const response = await apiService.post("/admin/knowledge/base", payload);
  return response;
};

export const updateKnowledgeBase = async (payload, id) => {
  const response = await apiService.patch(
    `/admin/knowledge/base/${id}`,
    payload
  );
  return response;
};

export const deleteKnowledgeBase = async id => {
  const response = await apiService.delete(`/admin/knowledge/base/${id}`);
  return response;
};

export const getSingleKnowledgeBase = async id => {
  const response = await apiService.get(`/app/knowledge/base/${id}`);
  if (response.hasError) return response;
  return new EditSingleKnowledgeBase(response.data);
};

export const KnowledgeBaseDetail = async id => {
  const response = await apiService.get(`/app/knowledge/base/${id}`);
  if (response.hasError) return response;
  return new SingleKnowledgeBase(response.data);
};

export const createKnowledgeBaseParagraph = async (payload, id) => {
  const response = await apiService.post(
    `/admin/knowledge/base/${id}/paragraph`,
    payload
  );
  if (response.hasError) return response;
  return new Paragraph(response.data);
};

export const updateKnowledgeBaseParagraph = async (payload, paraId) => {
  const response = await apiService.patch(
    `/admin/knowledge/base/paragraph/${paraId}`,
    payload
  );
  if (response.hasError) return response;
  return response;
};

export const deleteKnowledgeBaseParagraph = async paraId => {
  const response = await apiService.delete(
    `/admin/knowledge/base/paragraph/${paraId}`
  );
  return response;
};

export const getAllKnowledgeBaseCategories = async () => {
  const response = await apiService.get("/app/knowledge/base/categories");
  if (response.hasError) return response;
  return response.data.map(category => new Category(category));
};

export const getAllAdminKnowledgeBase = async ({ page, perPage, search }) => {
  const response = await apiService.get(
    `/admin/knowledge/base?page=${page}&limit=${perPage}&search=${search}`
  );
  if (response.hasError) return response;
  const { items, count } = response.data;
  return {
    items: items.map(knowledge => new KnowledgeBase(knowledge)),
    count: count
  };
};

export const getAllKnowledgeBase = async (id, page, perPage) => {
  const response = await apiService.get(
    `/app/knowledge/base/all/${id}?page=${page}&limit=${perPage}`
  );
  if (response.hasError) return response;
  const { items, count } = response.data;
  return {
    items: items.map(knowledge => new KnowledgeBase(knowledge)),
    count: count
  };
};

export const searchKnowledgeBase = async (search, limit = 4) => {
  const response = await apiService.get(
    `/app/knowledge/base/search?limit=${limit}&search=${search}`
  );
  if (response.hasError) return response;
  return {
    groupTitle: "Uputstva",
    searchLimit: 4,
    data: response.data.items.map(
      knowledge => new KnowledgeBaseSearch(knowledge)
    )
  };
};
