import { apiService } from "./apiService";
import { Tag } from "../api/Models/Tag.entity";
import { Select } from "../api/Models/Select.entity";

export const createTag = async payload => {
  const response = await apiService.post("/admin/tag", payload);
  return response;
};

export const updateTag = async (payload, id) => {
  const response = await apiService.post(`/admin/tag/${id}`, payload);
  return response;
};

export const getTags = async () => {
  const response = await apiService.get("/admin/tag/all");
  if (response.hasError) return response;
  return response.data.map(tag => new Tag(tag));
};

export const getNewsTags = async () => {
  const response = await apiService.get("/app/news/tags");
  if (response.hasError) return response;
  return response.data.map(tag => new Select(tag));
};

export const deleteTag = async id => {
  const response = await apiService.delete(`/admin/tag/${id}`);
  if (response.hasError) return response;
  return response;
};

export const getArticleTags = async () => {
  const response = await apiService.get("/app/article/tags");
  if (response.hasError) return response;
  return response.data.map(tag => new Select(tag));
}

export const getKnowledgeBaseTags = async () => {
  const response = await apiService.get("/app/knowledge/base/tags");
  if (response.hasError) return response;
  return response.data.map(tag => new Select(tag));
}
