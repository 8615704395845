import { Image } from "./Image.entity";
class Question {
  constructor(data = {}) {
    this.id = data.id || null;
    this.title = data.title || "";
    this.imageUrl = data.imageUrl || "";
    this.image = data.image ? new Image(data.image) : new Image();
    this.answers = data.answers || [];
    this.correct = data.correct || "";
    this.collapse = false;
  }
}

class QuizQuestion {
  constructor(data = {}) {
    this.id = data.id || null;
    this.taskId = data.taskId || null;
    this.title = data.title || "";
    this.possibleAnswers = data.possibleAnswers || [];
    this.questionNumber = data.questionNumber || 0;
    this.image = data.image ? new Image(data.image) : new Image();
  }
}

class AnswerResponse {
  constructor(data = {}) {
    this.id = data.id || null;
    this.nextQuestionId = data.next_question_id || null;
    this.correctAnswer = data.correct_answer || "";
    this.isCorrect = data.is_correct || false;
    this.numberOfQuestions = data.number_of_questions || 0;
    this.questionNumber = data.question_number || 0;
    this.reward = data.reward || null;
  }
}

export { Question, QuizQuestion, AnswerResponse };
