import axios from "axios";
import { toast } from "react-toastify";

import { getItem } from "../localStorageService";
import { BASE_API_URL } from "../../constants/apiConstants";
import { TOKEN } from "../../constants";

class ApiService {
  constructor() {
    let service = axios.create({
      baseURL: BASE_API_URL
    });
    service.interceptors.request.use(
      this.handleReqInterceptorSuccess,
      this.handleReqInterceptorError
    );
    service.interceptors.response.use(
      this.handleResInterceptorSuccess,
      this.handleResInterceptorError
    );
    this.service = service;
  }

  handleReqInterceptorSuccess(request) {
    if (request.baseURL === BASE_API_URL && !request.headers.Authorization) {
      const token = getItem(TOKEN);

      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      }
    }
    return request;
  }

  handleReqInterceptorError = error => {
    console.log("request interceptor error", error.response);
    return Promise.reject(error);
  };

  handleResInterceptorSuccess(response) {
    return response;
  }

  handleResInterceptorError = error => {
    console.log("response interceptor error", error.response.status);
    // and write error in log.file or send via slack service...
    if (error.response.status === 401) {
      toast.error(error.response.data.message);
    }
    if (error.response.status === 404) {
      toast.error("Bad request!");
    }
    if (error.response.status === 500) {
      toast.error("Server error!");
    }
    return Promise.reject(error);
  };

  payloadClear = payload => {
    Object.keys(payload).forEach(
      key =>
        (payload[key] === "" ||
          payload[key] === null ||
          payload[key] === undefined) &&
        delete payload[key]
    );
  };

  async handleApiCall(apiCall) {
    try {
      const response = await apiCall();
      return response;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  }

  async get(path) {
    return this.handleApiCall(() => this.service.get(path));
  }

  async post(path, payload) {
    const cleanPayload = payload;
    this.payloadClear(cleanPayload);
    return this.handleApiCall(() =>
      this.service.request({
        method: "POST",
        url: path,
        data: cleanPayload
      })
    );
  }

  async put(path, payload) {
    const cleanPayload = payload;
    this.payloadClear(cleanPayload);
    return this.handleApiCall(() =>
      this.service.request({
        method: "PUT",
        url: path,
        data: cleanPayload
      })
    );
  }

  async patch(path, payload) {
    const cleanPayload = payload;
    this.payloadClear(cleanPayload);
    return this.service.request({
      method: "PATCH",
      url: path,
      data: cleanPayload
    });
  }

  async delete(path) {
    return this.handleApiCall(() => this.service.delete(path));
  }
}

export const apiService = new ApiService();
