import { Image } from "./Image.entity";
import { Paragraph, RawParagraph } from "./Paragraph.entity";
import { Account } from "./User.entity";
import { Document } from "./Document.entity";
class Article {
  constructor(data = {}) {
    this.id = data.id || null;
    this.title = data.title || "";
    this.introText = data.introText || "";
    this.categories = data.categories || [];
    this.tags = data.tags || [];
    this.imageUrl = data.imageUrl || null;
    this.type = data.type || "public";
    this.image = new Image(data.image);
    this.user = new Account(data.user) || null;
    this.documents = data.documents
      ? data.documents.map(doc => new Document(doc))
      : [];
    this.documentUrls = [];
    this.created = new Date(data.created).toLocaleDateString() || "";
  }
}

class SingleArticle {
  constructor(data = {}) {
    this.id = data.id || null;
    this.title = data.title || "";
    this.introText = data.introText || "";
    this.categories = data.categories || [];
    this.tags = data.tags || [];
    this.imageUrl = data.imageUrl || null;
    this.type = data.type || "public";
    this.image = new Image(data.image);
    this.views = data.views || 0;
    this.user = data.user || null;
    this.created = new Date(data.created).toLocaleDateString() || "";
    this.documents = data.documents
      ? data.documents.map(doc => new Document(doc))
      : [];
    this.paragraphs = data.paragraphs
      ? data.paragraphs.map(para => new RawParagraph(para))
      : [];
  }
}

class ArticleSearch {
  constructor(data = {}) {
    this.id = data.id || null;
    this.target = "Artikli";
    this.title = data.title || "";
    this.link = `articleDetail/${this.id}`;
    this.icon = "Home";
  }
}

class EditSingleArticle {
  constructor(data = {}) {
    this.id = data.id || null;
    this.title = data.title || "";
    this.introText = data.introText || "";
    this.categories = data.categories ? data.categories.map(cat => cat.id) : [];
    this.tags = data.tags ? data.tags.map(tag => tag.id) : [];
    this.imageUrl = data.imageUrl || null;
    this.type = data.type || "";
    this.image = new Image(data.image);
    this.articleCollapse = false;
    this.documents = data.documents
      ? data.documents.map(doc => new Document(doc))
      : [];
    this.documentUrls = [];
    this.paragraphs = data.paragraphs
      ? data.paragraphs.map(para => new Paragraph(para))
      : [];
  }
}

export { Article, EditSingleArticle, SingleArticle, ArticleSearch };
