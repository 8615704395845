import { lazy } from "react";

// user pages
const news = lazy(() => import("../views/pages/customPages/News"));
const newsDetail = lazy(() => import("../views/pages/customPages/NewsDetail"));
const professionalArticles = lazy(() =>
  import("../views/pages/customPages/ProfessionalArticles")
);
const professionalArticlesTable = lazy(() =>
  import("../views/pages/customPages/ProfessionalArticlesTable")
);
// const professionalArticlesDetails = lazy(() =>
//   import("../views/pages/customPages/ProfessionalArticlesDetails")
// );
const events = lazy(() => import("../views/pages/customPages/Events"));
const eventDetails = lazy(() =>
  import("../views/pages/customPages/EventDetails")
);
const knowledgeBaseCategory = lazy(() =>
  import("../views/pages/customPages/KnowledgeBaseCategory")
);
const knowledgeBase = lazy(() =>
  import("../views/pages/customPages/KnowledgeBase")
);
const knowledgeBaseDetails = lazy(() =>
  import("../views/pages/customPages/KnowledgeBaseDetails")
);
const chat = lazy(() => import("../views/pages/customPages/Chat"));
const userProfile = lazy(() =>
  import("../views/pages/customPages/profile/Profile")
);
const myProfile = lazy(() =>
  import("../views/pages/customPages/myProfile/Profile")
);
const createArticle = lazy(() =>
  import("../views/pages/customPages/CreateArticle/createArticle")
);
const editArticle = lazy(() =>
  import("../views/pages/customPages/EditArticle/editArticle")
);
const articleDetail = lazy(() =>
  import("../views/pages/customPages/ArticleDetail")
);

// admin pages
const adminNews = lazy(() => import("../views/pages/customPages/admin/News"));
const createNews = lazy(() =>
  import("../views/pages/customPages/admin/CreateNews/CreateNews")
);
const editNews = lazy(() =>
  import("../views/pages/customPages/admin/EditNews/EditNews")
);

const adminProfessionalArticles = lazy(() =>
  import("../views/pages/customPages/admin/ProfessionalArticles")
);

const adminEvents = lazy(() =>
  import("../views/pages/customPages/admin/Events")
);
const createEvent = lazy(() =>
  import("../views/pages/customPages/admin/CreateEvent/CreateEvent")
);
const editEvent = lazy(() =>
  import("../views/pages/customPages/admin/EditEvent/EditEvent")
);

const adminQuizzes = lazy(() =>
  import("../views/pages/customPages/admin/Quizzes")
);

const createQuiz = lazy(() =>
  import("../views/pages/customPages/admin/CreateQuiz/CreateQuiz")
);

const editQuiz = lazy(() =>
  import("../views/pages/customPages/admin/EditQuiz/EditQuiz")
);

const adminKnowledgeBase = lazy(() =>
  import("../views/pages/customPages/admin/KnowledgeBase")
);

const adminNewsletter = lazy(() =>
import("../views/pages/customPages/admin/Newsletter")
);

const categoriesAndTags = lazy(() =>
  import("../views/pages/customPages/admin/CategoriesAndTags/CategoriesAndTags")
);

const adminUsers = lazy(() => import("../views/pages/customPages/admin/Users"));
const invitations = lazy(() =>
  import("../views/pages/customPages/admin/Invitations")
);

const createKnowledgeBase = lazy(() =>
  import(
    "../views/pages/customPages/admin/CreateKnowledgeBase/createKnowledgeBase"
  )
);

const editKnowledgeBase = lazy(() =>
  import("../views/pages/customPages/admin/EditKnowledgeBase/EditKnowledgeBase")
);

const editProfile = lazy(() =>
  import("../views/pages/customPages/EditProfile")
);

// public pages
const login = lazy(() => import("../views/pages/authentication/login/Login"));
const register = lazy(() =>
  import("../views/pages/authentication/register/Register")
);
const forgotPassword = lazy(() =>
  import("../views/pages/authentication/ForgotPassword")
);
const resetPassword = lazy(() =>
  import("../views/pages/authentication/ResetPassword")
);

const publicRoutes = [
  {
    path: "/",
    component: login,
    exact: true,
    fullLayout: true
  },
  {
    path: "/register/:hash",
    component: register,
    exact: false,
    fullLayout: true
  },
  {
    path: "/pages/forgot-password",
    component: forgotPassword,
    exact: false,
    fullLayout: true
  },
  {
    path: "/reset-password/:hash",
    component: resetPassword,
    exact: false,
    fullLayout: true
  }
];

const userRoutes = [
  {
    path: "/",
    component: news,
    exact: true,
    fullLayout: false
  },
  {
    path: "/newsDetail/:id([0-9a-fA-F-]+)",
    component: newsDetail,
    exact: false,
    fullLayout: false
  },
  {
    path: "/professionalArticles",
    component: professionalArticles,
    exact: false,
    fullLayout: false
  },
  {
    path: "/professionalArticlesTable",
    component: professionalArticlesTable,
    exact: false,
    fullLayout: false
  },
  // {
  //   path: "/professionalArticlesDetails",
  //   component: professionalArticlesDetails,
  //   exact: false,
  //   fullLayout: false
  // },
  {
    path: "/events",
    component: events,
    exact: false,
    fullLayout: false
  },
  {
    path: "/eventDetails/:id([0-9a-fA-F-]+)",
    component: eventDetails,
    exact: false,
    fullLayout: false
  },
  {
    path: "/knowledgeBaseCategory",
    component: knowledgeBaseCategory,
    exact: false,
    fullLayout: false
  },
  {
    path: "/knowledgeBase/category/:id([0-9a-fA-F-]+)",
    component: knowledgeBase,
    exact: false,
    fullLayout: false
  },
  {
    path: "/knowledgeBaseDetails/:id([0-9a-fA-F-]+)",
    component: knowledgeBaseDetails,
    exact: false,
    fullLayout: false
  },
  {
    path: "/user/:id([0-9a-fA-F-]+)",
    component: userProfile,
    exact: false,
    fullLayout: false
  },
  {
    path: "/user",
    component: myProfile,
    exact: false,
    fullLayout: false
  },
  {
    path: "/editProfile",
    component: editProfile,
    exact: false,
    fullLayout: false
  },
  {
    path: "/chat",
    component: chat,
    exact: false,
    fullLayout: false
  },
  {
    path: "/createArticle",
    component: createArticle,
    exact: false,
    fullLayout: false
  },
  {
    path: "/editArticle/:id([0-9a-fA-F-]+)",
    component: editArticle,
    exact: false,
    fullLayout: false
  },
  {
    path: "/articleDetail/:id([0-9a-fA-F-]+)",
    component: articleDetail,
    exact: false,
    fullLayout: false
  }
];

const adminRoutes = [
  {
    path: "/",
    component: adminNews,
    exact: true,
    fullLayout: false
  },
  {
    path: "/createNews",
    component: createNews,
    exact: true,
    fullLayout: false
  },
  {
    path: "/newsDetail/:id([0-9a-fA-F-]+)",
    component: newsDetail,
    exact: false,
    fullLayout: false
  },
  {
    path: "/editNews/:id([0-9a-fA-F-]+)",
    component: editNews,
    exact: true,
    fullLayout: false
  },
  {
    path: "/professionalArticles",
    component: adminProfessionalArticles,
    exact: false,
    fullLayout: false
  },
  {
    path: "/createAdminArticle",
    component: createArticle,
    exact: false,
    fullLayout: false
  },
  {
    path: "/editArticle/:id([0-9a-fA-F-]+)",
    component: editArticle,
    exact: false,
    fullLayout: false
  },
  {
    path: "/articleDetail/:id([0-9a-fA-F-]+)",
    component: articleDetail,
    exact: false,
    fullLayout: false
  },
  {
    path: "/events",
    component: adminEvents,
    exact: false,
    fullLayout: false
  },
  {
    path: "/createEvent",
    component: createEvent,
    exact: false,
    fullLayout: false
  },
  {
    path: "/editEvent/:id([0-9a-fA-F-]+)",
    component: editEvent,
    exact: false,
    fullLayout: false
  },
  {
    path: "/quizzes",
    component: adminQuizzes,
    exact: false,
    fullLayout: false
  },
  {
    path: "/createQuiz",
    component: createQuiz,
    exact: false,
    fullLayout: false
  },
  {
    path: "/editQuiz/:id([0-9a-fA-F-]+)",
    component: editQuiz,
    exact: false,
    fullLayout: false
  },
  {
    path: "/knowledgeBaseCategory",
    component: adminKnowledgeBase,
    exact: false,
    fullLayout: false
  },
  {
    path: "/createKnowledgeBase",
    component: createKnowledgeBase,
    exact: false,
    fullLayout: false
  },
  {
    path: "/editKnowledgeBase/:id([0-9a-fA-F-]+)",
    component: editKnowledgeBase,
    exact: false,
    fullLayout: false
  },
  {
    path: "/categoriesAndTags",
    component: categoriesAndTags,
    exact: false,
    fullLayout: false
  },
  {
    path: "/users",
    component: adminUsers,
    exact: false,
    fullLayout: false
  },
  {
    path: "/invitations",
    component: invitations,
    exact: false,
    fullLayout: false
  },
  {
    path: "/send/newsletter",
    component: adminNewsletter,
    exact: false,
    fullLayout: false
  }
];

export const routesConfig = {
  user: userRoutes,
  admin: adminRoutes,
  public: publicRoutes
};
