import { Image } from "./Image.entity";
import { Paragraph, RawParagraph } from "./Paragraph.entity";
import { EventUser } from "./User.entity";
import { Quiz } from "./Quiz.entity";
class Event {
  constructor(data = {}) {
    this.id = data.id || null;
    this.title = data.title || "";
    this.introText = data.introText || "";
    this.imageUrl = data.imageUrl || null;
    this.image = new Image(data.image) || new Image();
    this.startDate = data.startDate || new Date();
    this.endDate = data.endDate || new Date();
    this.type = data.type || "";
    this.created = new Date(data.created).toLocaleDateString() || "";
    this.invitations = data.invitations || [];
    this.conferenceUrl = data.conferenceUrl || "";
    this.conferenceChatUrl = data.conferenceChatUrl || "";
    this.conferenceAudioLink = data.conferenceAudioLink || "";
    this.eventCollapse = false;
    this.paragraphs = data.paragraphs
      ? data.paragraphs.map(para => new Paragraph(para))
      : [];
  }
}

class SingleEvent {
  constructor(data = {}) {
    this.id = data.id || null;
    this.title = data.title || "";
    this.introText = data.introText || "";
    this.imageUrl = data.imageUrl || null;
    this.image = new Image(data.image) || new Image();
    this.startDate =
      new Date(data.startDate).toLocaleDateString() || new Date();
    this.endDate = new Date(data.endDate).toLocaleDateString() || new Date();
    this.type = data.type || "";
    this.invitations = data.invitations || [];
    this.conferenceUrl = data.conferenceUrl || "";
    this.conferenceChatUrl = data.conferenceChatUrl || "";
    this.conferenceAudioLink = data.conferenceAudioLink || "";
    this.created = new Date(data.created).toLocaleDateString() || "";
    this.paragraphs = data.paragraphs
      ? data.paragraphs.map(para => new RawParagraph(para))
      : [];
    this.user = new EventUser(data.user) || new EventUser();
    this.quiz = data.task ? new Quiz(data.task) : new Quiz();
  }
}

class EventForCalendar {
  constructor(data = {}) {
    this.id = data.id || null;
    this.title = data.title || "";
    this.start = data.startDate || new Date();
    this.end = data.endDate || new Date();
    this.label = data.type || "";
    this.allDay = false;
    this.selectable = true;
  }
}

export { Event, EventForCalendar, SingleEvent };
