class Tag {
  constructor(data = {}) {
    this.id = data.id || null;
    this.name = data.name || "";
    this.description = data.description || "";
    this.type = data.type || "";
  }
}

export { Tag };
