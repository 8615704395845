import { apiService } from "./apiService";

export const presignedUpload = async payload => {
  const response = await apiService.post("/app/file/presign-upload/image", {
    extensions: [payload]
  });
  if (response.hasErrors) {
    return response;
  }
  return response;
};

export const presignedUploadDocs = async payload => {
  const response = await apiService.post("/app/file/presign-upload/document", {
    extensions: [payload]
  });
  if (response.hasErrors) {
    return response;
  }
  return response;
};
