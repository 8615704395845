import { apiService } from "./apiService";
import {
  EditSingleNews,
  News,
  SingleNews,
  NewsSearch
} from "./Models/News.entity";
import { Paragraph } from "./Models/Paragraph.entity";

export const createNews = async payload => {
  const response = await apiService.post("/admin/news", payload);
  return response;
};

export const updateNews = async (payload, id) => {
  const response = await apiService.patch(`/admin/news/${id}`, payload);
  return response;
};

export const deleteNews = async id => {
  const response = await apiService.delete(`/admin/news/${id}`);
  return response;
};

export const getSingleNews = async id => {
  const response = await apiService.get(`/app/news/${id}`);
  if (response.hasError) return response;
  return new EditSingleNews(response.data);
};

export const getNewsDetail = async id => {
  const response = await apiService.get(`/app/news/${id}`);
  if (response.hasError) return response;
  return new SingleNews(response.data);
};

export const createNewsParagraph = async (payload, id) => {
  const response = await apiService.post(
    `/admin/news/${id}/paragraph`,
    payload
  );
  if (response.hasError) return response;
  return new Paragraph(response.data);
};

export const updateNewsParagraph = async (payload, paraId) => {
  const response = await apiService.patch(
    `/admin/news/paragraph/${paraId}`,
    payload
  );
  if (response.hasError) return response;
  return response;
};

export const deleteNewsParagraph = async paraId => {
  const response = await apiService.delete(`/admin/news/paragraph/${paraId}`);
  return response;
};

export const getAllAdminNews = async ({ page, perPage, search }) => {
  const response = await apiService.get(
    `/admin/news?page=${page}&limit=${perPage}&search=${search}`
  );
  if (response.hasError) return response;
  const { items, count } = response.data;
  return { news: items.map(news => new News(news)), count: count };
};

export const getAllNews = async (page, perPage) => {
  const response = await apiService.get(
    `/app/news/all?page=${page}&limit=${perPage}`
  );
  if (response.hasError) return response;
  const { items, count } = response.data;
  return { news: items.map(news => new News(news)), count: count };
};

export const searchNews = async (search, limit = 4) => {
  const response = await apiService.get(
    `/app/news/search?limit=${limit}&search=${search}`
  );
  if (response.hasError) return response;
  return {
    groupTitle: "Vesti",
    searchLimit: 4,
    data: response.data.items.map(news => new NewsSearch(news))
  };
};
