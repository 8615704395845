import { apiService } from "./apiService";
import { Quiz, StartedQuiz, QuizReward } from "./Models/Quiz.entity";
import { QuizQuestion, AnswerResponse } from "./Models/Question.entity";

export const createQuiz = async payload => {
  const response = await apiService.post("/admin/task", payload);
  return response;
};

export const updateQuiz = async (payload, id) => {
  const response = await apiService.patch(`/admin/task/${id}`, payload);
  return response;
};

export const createQuestion = async (payload, taskId) => {
  const response = await apiService.post(
    `/admin/task/${taskId}/question`,
    payload
  );
  return response;
};

export const updateQuestion = async (payload, questionId) => {
  const response = await apiService.patch(
    `/admin/task/question/${questionId}`,
    payload
  );
  return response;
};

export const deleteQuiz = async id => {
  const response = await apiService.delete(`/admin/task/${id}`);
  return response;
};

export const getAllAdminTasks = async ({ page, perPage, search }) => {
  const response = await apiService.get(
    `/admin/task?page=${page}&limit=${perPage}&search=${search}`
  );
  if (response.hasError) return response;
  const { tasks, count } = response.data;
  return { items: tasks.map(task => new Quiz(task)), count: count };
};

export const getUserQuiz = async id => {
  const response = await apiService.get(`/app/task/${id}`);
  if (response.hasError) return response;
  return new StartedQuiz(response.data);
};

export const getQuestion = async (quizId, questionId) => {
  const response = await apiService.get(
    `/app/task/${quizId}/question/${questionId}`
  );
  if (response.hasError) return response;
  return new QuizQuestion(response.data);
};

export const sendAnswer = async (quizId, questionId, payload) => {
  const response = await apiService.post(
    `/app/task/${quizId}/question/${questionId}`,
    payload
  );
  if (response.hasError) return response;
  return new AnswerResponse(response.data);
};

export const getSingleQuiz = async id => {
  const response = await apiService.get(`/admin/task/${id}`);
  if (response.hasError) return response;
  return new Quiz(response.data);
};

export const fetchAllRewards = async ({ taskId, page, perPage }) => {
  const response = await apiService.get(`admin/task/${taskId}/reward?page=${page}&limit=${perPage}`);
  if (response.hasError) return response;
  const { items, count } = response.data;
  return {rewards: items.map(reward => new QuizReward(reward)), count: count};
}
