
export const getChats = payload => {
  return dispatch => {
    dispatch({
      type: "GET_CONTACTS",
      payload
    });
  }
}

export const getMessages = payload => {
  return dispatch => {
    dispatch({
      type: "GET_MESSAGES",
      payload: payload.reverse()
    })
  }
}

export const setSocketInfo = ({socketUrl, hash}) => {
  return dispatch => {
    dispatch({
      type: "SET_SOCKET",
      socketUrl,
      hash
    })
  }
}

export const sendMessage = (msg) => {
  return dispatch => {
      dispatch({
        type: 'SEND_MESSAGE',
        payload: msg
      })
    }
}

export const updateRoom = (room) => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_ROOM',
      payload: room
    })
  }
}

export const unreadMessage = payload => {
  return dispatch => {
    dispatch({
      type: "UNREAD_MESSAGE",
      payload: payload.unreadMessages
    })
  }
}


// export const getContactChats = () => {
//   return dispatch => {
//     axios
//       .get("api/app/chat/chat-contacts")
//       .then(response => {
//         dispatch({
//           type: "GET_CHAT_CONTACTS",
//           chats: response.data
//         })
//       })
//       .catch(err => console.log(err))
//   }
// }

// export const togglePinned = (id, value) => {
//   return dispatch => {
//     axios
//       .post("/api/apps/chat/set-pinned/", {
//         contactId: id,
//         value
//       })
//       .then(response => {
//         dispatch({
//           type: "SET_PINNED",
//           id,
//           value
//         })
//       })
//       .catch(err => console.log(err))
//   }
// }

// export const changeStatus = status => {
//   return dispatch => {
//     dispatch({
//       type: "CHANGE_STATUS",
//       status
//     })
//   }
// }

// export const searchContacts = query => {
//   return dispatch => {
//     dispatch({
//       type: "SEARCH_CONTACTS",
//       query
//     })
//   }
// }

// export const markSeenAllMessages = id => {
//   return dispatch => {
//     axios
//       .post("/api/apps/chat/mark-all-seen/", {
//         contactId: id
//       })
//       .then(response => {
//         dispatch({
//           type: "MARK_AS_SEEN",
//           id
//         })
//       })
//       .catch(err => console.log(err))
//   }
// }
