import { User } from "../../../services/api/Models/User.entity";
import { routesConfig } from "../../../configs/routerConfig";
import { getItem } from "../../../services/localStorageService";
import { ROLE } from "../../../constants";
const initialState = {
  user: new User(),
  routes: routesConfig[getItem(ROLE)]
    ? routesConfig[getItem(ROLE)]
    : routesConfig["public"]
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    // case "LOGIN_WITH_EMAIL": {
    //   return { ...state, values: action.payload };
    // }
    // case "LOGIN_WITH_FB": {
    //   return { ...state, values: action.payload };
    // }
    // case "LOGIN_WITH_TWITTER": {
    //   return { ...state, values: action.payload };
    // }
    // case "LOGIN_WITH_GOOGLE": {
    //   return { ...state, values: action.payload };
    // }
    // case "LOGIN_WITH_GITHUB": {
    //   return { ...state, values: action.payload };
    // }
    case "SET_USER": {
      return {
        ...state,
        user: {
          ...state.user,
          profile: action.payload
        }
      };
    }
    case "LOGIN_WITH_JWT": {
      return {
        ...state,
        user: action.payload.user,
        routes: action.payload.routes
      };
    }
    case "LOGOUT_WITH_JWT": {
      return { ...state, user: new User(), routes: routesConfig["public"] };
    }
    // case "LOGOUT_WITH_FIREBASE": {
    //   return { ...state, values: action.payload };
    // }
    // case "CHANGE_ROLE": {
    //   return { ...state, userRole: action.userRole };
    // }
    default: {
      return state;
    }
  }
};
