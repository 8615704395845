import { apiService } from "./apiService";
import {
  EditSingleArticle,
  Article,
  SingleArticle,
  ArticleSearch
} from "./Models/Article.entity";
import { Paragraph } from "./Models/Paragraph.entity";

export const createArticle = async payload => {
  const response = await apiService.post("/app/article", payload);
  return response;
};

export const updateArticle = async (payload, id) => {
  const response = await apiService.patch(`/app/article/${id}`, payload);
  return response;
};

export const getSingleArticle = async id => {
  const response = await apiService.get(`/app/article/${id}`);
  if (response.hasError) return response;
  return new EditSingleArticle(response.data);
};

export const getArticleDetail = async id => {
  const response = await apiService.get(`/app/article/${id}`);
  if (response.hasError) return response;
  return new SingleArticle(response.data);
};

export const deleteDocument = async (articleId, documentId) => {
  const response = await apiService.delete(
    `/app/article/${articleId}/document?documentId=${documentId}`
  );
  return response;
};

export const createArticleParagraph = async (payload, id) => {
  const response = await apiService.post(
    `/app/article/${id}/paragraph`,
    payload
  );
  if (response.hasError) return response;
  return new Paragraph(response.data);
};

export const updateArticleParagraph = async (payload, paraId) => {
  const response = await apiService.patch(
    `/app/article/paragraph/${paraId}`,
    payload
  );
  if (response.hasError) return response;
  return response;
};

export const deleteArticleParagraph = async paraId => {
  const response = await apiService.delete(`/app/article/paragraph/${paraId}`);
  return response;
};

export const getAllArticles = async (page, perPage, search = "") => {
  const response = await apiService.get(
    `/app/article?page=${page}&limit=${perPage}&search=${search}`
  );
  if (response.hasError) return response;
  const { items, count } = response.data;
  return { articles: items.map(article => new Article(article)), count: count };
};

export const getAllAdminArticles = async (page, perPage, search = "") => {
  const response = await apiService.get(
    `/app/article/all?page=${page}&limit=${perPage}&search=${search}`
  );
  if (response.hasError) return response;
  const { items, count } = response.data;
  return { articles: items.map(article => new Article(article)), count: count };
};

export const getMyArticles = async (page, perPage) => {
  const response = await apiService.get(
    `/app/article/myArticle?page=${page}&limit=${perPage}`
  );
  if (response.hasError) return response;
  const { items, count } = response.data;
  return { articles: items.map(article => new Article(article)), count: count };
};

export const getArticleFromAccount = async (
  accountId,
  page = 1,
  perPage = 10
) => {
  const response = await apiService.get(
    `/app/article/from/account/${accountId}?page=${page}&limit=${perPage}`
  );
  if (response.hasError) return response;
  const { items, count } = response.data;
  return { articles: items.map(article => new Article(article)), count: count };
};

export const searchArticle = async (search, limit = 4) => {
  const response = await apiService.get(
    `/app/article/search?limit=${limit}&search=${search}`
  );
  if (response.hasError) return response;
  return {
    groupTitle: "Artikli",
    searchLimit: 4,
    data: response.data.items.map(article => new ArticleSearch(article))
  };
};

export const deleteArticle = async id => {
  const response = await apiService.delete(`/app/article/${id}`);
  return response;
};
