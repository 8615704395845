import minPlaceholder from "../../../assets/img/logo/vuesax-logo-2.png";
import maxPlaceholder from "../../../assets/img/backgrounds/placeholder1920.jpg";
class Image {
  constructor(data = {}) {
    this.id = data.id || null;
    this.base = data.base || "";
    this.name = data.name || "";
    this.sizes = data.sizes || [];
    this.types = data.types || [];
    this.path = data.path || "";
  }

  getMinImageUrl(){
    if (this.id) {
      return `${this.base}/${this.path}/${Math.min.apply(null, this.sizes)}/${this.name}.${this.types[0] || 'jpeg'}`;
    } else {
      return minPlaceholder;
    } 
  }

  getMaxImageUrl(){
    if (this.id) {
      return `${this.base}/${this.path}/${Math.max.apply(null, this.sizes)}/${this.name}.${this.types[0] || 'jpeg'}`;
    } else {
      return maxPlaceholder;
    } 
  }

  getMaxImageUrlPng(){
    if (this.id) {
      return `${this.base}/${this.path}/${Math.max.apply(null, this.sizes)}/${this.name}.${this.types[1] || 'png'}`;
    } else {
      return maxPlaceholder;
    } 
  }
}

export { Image };
