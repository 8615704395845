const initialState = {
  chats:[],
  unreadMessage: 0,
  messages: [],
  status: "active",
}

const chats = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONTACTS":
      return { ...state, chats: action.payload }
    case "CHANGE_STATUS":
      return { ...state, status: action.status }
    case "UNREAD_MESSAGE":
      return { ...state, unreadMessage: action.payload }
    case "GET_MESSAGES":
      return {...state, messages: action.payload}
    case "SET_SOCKET":
      return {...state, socketUrl: action.socketUrl,hash: action.hash }
    case "SEND_MESSAGE":
      return {...state, messages: [...state.messages, action.payload] }
    case "UPDATE_ROOM":
      const rooms = state.chats.filter(chat => chat.id !== action.payload.roomId);
      const room = state.chats.filter(chat => chat.id === action.payload.roomId);
      const updatedRoom = {...room[0], ...action.payload};
        return {
          ...state, 
          chats: [updatedRoom, ...rooms]
        }
    default:
      return { ...state }
  }
}

// case "SEND_MESSAGE":
//   let sendMsg,
//     newChatContacts,
//     oldChatContactsId = state.chatContacts.map(j => j.uid)
//   if (state.chats[action.id]) {
//     let oldState = state.chats[action.id].msg
//     sendMsg = [...oldState, action.msg]
//   } else {
//     sendMsg = {
//       ...state.chats,
//       [action.id]: {
//         isPinned: action.isPinned,
//         msg: [action.msg]
//       }
//     }
//   }
//   if (!oldChatContactsId.includes(action.id)) {
//     let extractContactInfo = state.contacts.find(k => k.uid === action.id)
//     newChatContacts = state.chatContacts.concat(extractContactInfo)
//   } else {
//     newChatContacts = state.chatContacts
//   }
//   return { ...state, chats: sendMsg, chatContacts: newChatContacts }

export default chats
