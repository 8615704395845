import React, { useState, useEffect } from "react";
import { Navbar } from "reactstrap";
import { connect } from "react-redux";
import classnames from "classnames";
import { useAuth0 } from "../../../authServices/auth0/auth0Service";
import {
  logoutWithJWT,
  // logoutWithFirebase,
  setUser
} from "../../../redux/actions/auth/loginActions";
import * as api from "../../../services/api";
import NavbarBookmarks from "./NavbarBookmarks";
import NavbarUser from "./NavbarUser";
import userImg from "../../../assets/img/logo/logo.png";
import { toast } from "react-toastify";
import { getItem } from "../../../services/localStorageService";
import { ROLE } from "../../../constants";

const UserName = props => {
  let username = "";
  // if (props.userdata !== undefined) {
  //   username = props.userdata.name;
  // } else if (props.user.login.values !== undefined) {
  //   username = props.user.login.values.name;
  //   if (
  //     props.user.login.values.loggedInWith !== undefined &&
  //     props.user.login.values.loggedInWith === "jwt"
  //   ) {
  //     username = props.user.login.values.loggedInUser.name;
  //   }
  // } else {
  //   username = "John Doe";
  // }
  if (props.user.login.user.profile !== undefined) {
    username = `${props.user.login.user.profile.firstName} ${props.user.login.user.profile.lastName}`;
  } else {
    username = "";
  }

  return username;
};
const ThemeNavbar = props => {
  const { user } = useAuth0();
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
  const navbarTypes = ["floating", "static", "sticky", "hidden"];
  const [notifications, setNotifications] = useState([]);

  const fetchProfile = async () => {
    const profile = await api.fetchMyProfile();
    if (profile.hasError) {
    } else {
      props.setUser(profile);
    }
  };

  if (props.user.login.user.profile === undefined) {
    fetchProfile();
  }

  const fetchFriendRequests = async () => {
    const response = await api.getFriendRequests();
    if (response.hasError) {
    } else {
      setNotifications(response);
    }
  };

  const acceptFriendship = async (friendRequestId, payload) => {
    const response = await api.acceptFriendRequest(friendRequestId, payload);
    if (response.hasError) {
      toast.success(response.error.data.message);
    } else {
      setNotifications(notifications.filter(not => not.id !== friendRequestId));
      toast.success(response.data.message);
    }
  };

  useEffect(() => {
    if (getItem(ROLE) === "user") {
      fetchFriendRequests();
    }
  }, []);

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light":
              props.navbarColor === "default" ||
              !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            scrolling: props.horizontal && props.scrolling
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                <NavbarBookmarks
                  role={props.user.login.user.role}
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </div>
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">MedHub</h2>
                </div>
              ) : null}
              <NavbarUser
                role={props.user.login.user.role}
                notifications={notifications}
                acceptFriendship={acceptFriendship}
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={<UserName userdata={user} {...props} />}
                userImg={
                  props.user.login.user.profile !== undefined &&
                  // props.user.login.values.loggedInWith !== "jwt" &&
                  props.user.login.user.profile
                    ? props.user.login.user.profile.image.getMinImageUrl()
                    : userImg
                }
                loggedInWith={
                  props.user !== undefined &&
                  props.user.login.values !== undefined
                    ? props.user.login.values.loggedInWith
                    : null
                }
                logoutWithJWT={props.logoutWithJWT}
                // logoutWithFirebase={props.logoutWithFirebase}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth
  };
};

export default connect(mapStateToProps, {
  logoutWithJWT,
  // logoutWithFirebase,
  setUser,
  useAuth0
})(ThemeNavbar);
