import { Image } from "./Image.entity";

class Category {
  constructor(data = {}) {
    this.id = data.id || null;
    this.name = data.name || "";
    this.type = data.type || "";
    this.description = data.description || "";
    this.parentId = data.parentId || null;
    this.imageUrl = data.imageUrl || "";
    this.imageSrc = data.image ? new Image(data.image) : undefined;
  }
}

export { Category };
