import { apiService } from "./apiService";
import { User } from "./Models/User.entity";
import { setItem, removeItem } from "../localStorageService";
import { TOKEN, EXP, REFRESH_TOKEN, ROLE } from "../../constants";
import { routesConfig } from "../../configs/routerConfig";

export const loginUser = async payload => {
  const response = await apiService.post("/app/auth/login", payload);
  if (response.hasError) return response;
  setItem(TOKEN, response.data.auth.token);
  setItem(EXP, response.data.auth.exp);
  setItem(REFRESH_TOKEN, response.data.auth.refresh_token);
  setItem(ROLE, response.data.role);
  return {
    user: new User(response.data),
    routes: routesConfig[response.data.role]
  };
};

export const logoutUser = () => {
  // const response = await apiService.post('/app/auth/logout');
  // if (response.hasError) return response;
  removeItem(TOKEN);
  removeItem(EXP);
  removeItem(REFRESH_TOKEN);
  removeItem(ROLE);
};

export const validateStepOne = async payload => {
  const response = await apiService.post(
    "/app/auth/register/validation/step1",
    payload
  );
  return response;
};

export const validateStepTwo = async payload => {
  const response = await apiService.post(
    "/app/auth/register/validation/step2",
    payload
  );
  return response;
};

export const validateStepThree = async payload => {
  const response = await apiService.post(
    "/app/auth/register/validation/step3",
    payload
  );
  return response;
};

export const validateStepFour = async payload => {
  const response = await apiService.post(
    "/app/auth/register/validation/step4",
    payload
  );
  return response;
};

export const registerUser = async payload => {
  const response = await apiService.post("/app/auth/register", payload);
  if (response.hasError) return response;
  setItem(TOKEN, response.data.auth.token);
  setItem(EXP, response.data.auth.exp);
  setItem(REFRESH_TOKEN, response.data.auth.refresh_token);
  setItem(ROLE, response.data.role);
  return {
    user: new User(response.data),
    routes: routesConfig[response.data.role]
  };
};

export const forgotPassword = async payload => {
  const response = await apiService.post(
    "/app/account/forgot-password",
    payload
  );
  return response;
};

export const resetPassword = async payload => {
  const response = await apiService.post(
    "/app/account/reset-password",
    payload
  );
  return response;
};

export const sendInvitation = async email => {
  const response = await apiService.post("admin/auth/send/invitation", {
    email
  });
  if (response.hasError) return response;
  return response.data;
};
