import { apiService } from "./apiService";
import {
  Account,
  AccountSearch,
  FriendRequest,
  EditAccount
} from "./Models/User.entity";

export const fetchMyProfile = async id => {
  const response = await apiService.get(`app/account/profile`);
  if (response.hasError) return response;
  return new Account(response.data);
};

export const fetchProfileForEditAccount = async () => {
  const response = await apiService.get(`app/account/profile`);
  if (response.hasError) return response;
  return new EditAccount(response.data);
};

export const updateProfile = async payload => {
  const response = await apiService.patch(`app/account/update`, payload);
  return response;
};

export const fetchUserProfile = async id => {
  const response = await apiService.get(`app/account/profile/${id}`);
  if (response.hasError) return response;
  return new Account(response.data);
};

export const fetchUserFriends = async ({ id, page = 1, limit = 10 }) => {
  const response = await apiService.get(
    `app/account/profile/friends/${id}?page=${page}&limit=${limit}`
  );
  if (response.hasError) return response;
  const { items, count } = response.data;
  return { accounts: items.map(account => new Account(account)), count: count };
};

export const sendFriendRequest = async payload => {
  const response = await apiService.post(
    `/app/account/friend/request`,
    payload
  );
  return response;
};

export const getMyFriends = async ({ page = 1, limit = 10 }) => {
  const response = await apiService.get(
    `/app/account/my/friend/list?page=${page}&limit=${limit}`
  );
  if (response.hasError) return response;
  const { items, count } = response.data;
  return { accounts: items.map(account => new Account(account)), count: count };
};

export const getFriendRequests = async () => {
  const response = await apiService.get("/app/account/profile/friend/request");
  if (response.hasError) return response;
  return response.data.map(req => new FriendRequest(req));
};

export const acceptFriendRequest = async (friendRequestId, payload) => {
  const response = await apiService.patch(
    `/app/account/friend/request/status/${friendRequestId}`,
    payload
  );
  return response;
};

export const searchUsers = async (search, limit = 4) => {
  const response = await apiService.get(
    `/app/account/search?limit=${limit}&search=${search}`
  );
  if (response.hasError) return response;
  return {
    groupTitle: "Osobe",
    searchLimit: 4,
    data: response.data.items.map(account => new AccountSearch(account))
  };
};

export const removeFriend = async accountId => {
  const response = await apiService.post("/app/account/delete/friend/request", {
    accountId: accountId
  });
  if (response.hasError) return response;
  return response;
};

export const createAdminUser = async (param) => {
  const response = await apiService.post('admin/auth/register', param);
  if (response.hasError) return response;
  return response;
}

export const sendAdminNewsletter = async (param) => {
  const response = await apiService.post('admin/email/send/newsletter', param);
  if (response.hasError) return response;
  return response;
}
