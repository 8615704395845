class Message {
  constructor(data= {}) {
    this.id = data.id || null;
    this.fullName = data.fullName || '';
    this.accountId = data.accountId || '';
    this.message = data.message || '';
    this.isRead = data.isRead || false;
    this.created = data.createdAt || '';
  }
}

export default Message;
