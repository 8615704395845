class Auth {
  constructor(data = {}) {
    this.token = data.token || "";
    this.iat = data.iat || 0;
    this.exp = data.exp || 0;
    this.refreshToken = data.refresh_token || "";
  }
}

export { Auth };
