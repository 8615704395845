import { apiService } from "./apiService";
import { Category } from "./Models/Category.entity";
import { Select } from "./Models/Select.entity";

export const createCategory = async payload => {
  const response = await apiService.post("/admin/category", payload);
  return response;
};

export const updateCategory = async (payload, id) => {
  const response = await apiService.patch(`/admin/category/${id}`, payload);
  return response;
};

export const getCategories = async () => {
  const response = await apiService.get("/admin/category/all");
  if (response.hasError) return response;
  return response.data.map(category => new Category(category));
};

export const getNewsSubcategories = async () => {
  const response = await apiService.get("/app/news/categories");
  if (response.hasError) return response;
  return response.data.map(category => new Select(category));
};

export const getArticleCategories = async () => {
  const response = await apiService.get("/app/article/categories");
  if (response.hasError) return response;
  return response.data.map(category => new Select(category));
};

export const deleteCategory = async id => {
  const response = await apiService.delete(`/admin/category/${id}`);
  if (response.hasError) return response;
  return response;
};

export const getKnowledgeBaseCategories = async () => {
  const response = await apiService.get("/app/knowledge/base/categories");
  if (response.hasError) return response;
  return response.data.map(category => new Select(category));
};
