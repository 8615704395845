import mock from "../mock";
let data = {
  events: [
    {
      id: 1,
      title: "My Event",
      start: new Date(),
      end: new Date(),
      label: "business",
      allDay: true,
      selectable: true
    },
    {
      id: 1,
      title: "My Event",
      start: "2020-12-27T19:51:03.544Z",
      end: "2020-12-27T16:51:00.000Z",
      label: "business",
      allDay: false,
      selectable: true
    }
  ]
};

// GET : Calendar Events
mock.onGet("/api/apps/calendar/events").reply(() => {
  return [200, data.events];
});
