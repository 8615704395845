import { apiService } from "./apiService";
import { Select } from "./Models/Select.entity";
import { Account } from "./Models/User.entity";
import { Invitation } from "./Models/Invitation.entity";

export const getUsersByRole = async role => {
  const response = await apiService.get(`/admin/auth/users/${role}`);
  if (response.hasError) return response;
  return response.data.map(user => new Select(user));
};

export const getAllUsersAdmin = async ({ page, perPage, search }) => {
  const response = await apiService.get(
    `/admin/account/users?page=${page}&limit=${perPage}&search=${search}`
  );
  if (response.hasError) return response;
  const { items, count } = response.data;
  return { users: items.map(user => new Account(user)), count: count };
};

export const fetchInvitations = async ({page, perPage, search}) => {
  const response = await apiService.get(`admin/account/invitations?page=${page}&limit=${perPage}`);
  if (response.hasError) return response;
  const { items, count } = response.data;
  return { invitations: items.map(invitation => new Invitation(invitation)), count: count };
}
