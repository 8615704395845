class Select {
  constructor(data = {}) {
    this.value = data.id || data.accountId || null;
    data.name
      ? (this.label = data.name)
      : data.title
      ? (this.label = data.title)
      : (this.label =
          data.firstName && data.lastName
            ? `${data.firstName} ${data.lastName}`
            : "");
  }
}

export { Select };