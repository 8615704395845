// import { Tag } from "./Tag.entity";
// import { Category } from "./Category.entity";
import { Account } from "./User.entity";
import { Image } from "./Image.entity";
import { Paragraph, RawParagraph } from "./Paragraph.entity";

class News {
  constructor(data = {}) {
    this.id = data.id || null;
    this.title = data.title || "";
    this.introText = data.introText || "";
    this.categories = data.categories || [];
    this.tags = data.tags || [];
    this.imageUrl = data.imageUrl || null;
    this.image = new Image(data.image);
    this.user = new Account(data.user) || null;
    this.created = new Date(data.created).toLocaleDateString() || "";
  }
}

class SingleNews {
  constructor(data = {}) {
    this.id = data.id || null;
    this.title = data.title || "";
    this.introText = data.introText || "";
    this.categories = data.categories || [];
    this.tags = data.tags || [];
    this.imageUrl = data.imageUrl || null;
    this.image = new Image(data.image);
    this.user = data.user || null;
    this.views = data.views || 0;
    this.created = new Date(data.created).toLocaleDateString() || "";
    this.paragraphs = data.paragraphs
      ? data.paragraphs.map(para => new RawParagraph(para))
      : [];
  }
}

class NewsSearch {
  constructor(data = {}) {
    this.id = data.id || null;
    this.target = "Vesti";
    this.title = data.title || "";
    this.link = `newsDetail/${this.id}`;
    this.icon = "Home";
  }
}

class EditSingleNews {
  constructor(data = {}) {
    this.id = data.id || null;
    this.title = data.title || "";
    this.introText = data.introText || "";
    this.categories = data.categories ? data.categories.map(cat => cat.id) : [];
    this.tags = data.tags ? data.tags.map(tag => tag.id) : [];
    this.imageUrl = data.imageUrl || null;
    this.image = new Image(data.image);
    this.newsCollapse = false;
    this.paragraphs = data.paragraphs
      ? data.paragraphs.map(para => new Paragraph(para))
      : [];
  }
}

export { News, EditSingleNews, SingleNews, NewsSearch };
