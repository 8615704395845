import AccountProfile from './Profile.entity';
class Room {
  constructor(data ={}) {
    this.id = data.id || null;
    this.name = data.name || '';
    this.isRead = data.isRead || false;
    this.lastMessage = data.lastMessage || null;
    this.sender = new AccountProfile(data.sender);
    this.recipient = new AccountProfile(data.recipient);
    this.myAccount = data.myAccount || '';
    this.myAccountId = data.myAccountId || null;
  }
}

export default Room;