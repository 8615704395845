import { Auth } from "./Auth.entity";
import { getItem } from "../../localStorageService";
import { ROLE } from "../../../constants";
import { Image } from "./Image.entity";
class User {
  constructor(data = {}) {
    this.username = data.username || "";
    this.role = data.role || getItem(ROLE) || "";
    this.auth = new Auth(data.auth) || new Auth();
    this.profile = data.profile;
    this.image = new Image(data?.image);
  }
}

class EventUser {
  constructor(data = {}) {
    this.accountId = data.accountId || null;
    this.username = data.username || "";
    this.firstName = data.firstName || "";
    this.lastName = data.lastName || "";
  }
}

class Contact {
  constructor(data = {}) {
    this.country = data.country || "";
    this.callingCode = data.callingCode || "";
    this.phoneNumber = data.phoneNumber || "";
    this.city = data.city || "";
    this.postalCode = data.postalCode || "";
    this.address = data.address || "";
    this.showEmail = data.showEmail || false;
    this.showPhone = data.showPhone || false;    
  }
}

class AccountSearch {
  constructor(data = {}) {
    this.title = data.firstName ? `${data.firstName} ${data.lastName}` : "";
    this.email = data.email || "";
    this.img = new Image(data?.image).getMinImageUrl();
    this.date = "";
    this.link = `/user/${data.accountId}`;
  }
}

class Account {
  constructor(data = {}) {
    this.id = data.accountId || null;
    this.username = data.username || "";
    this.firstName = data.firstName || "";
    this.lastName = data.lastName || "";
    this.email = data.email || "";
    this.contact = new Contact(data.contact);
    this.image = new Image(data?.image);
    this.isFriend = data.isFriend || false;
    this.friends = data.friends
      ? data.friends.map(friend => new Account(friend))
      : [];
  }
}

class EditAccount {
  constructor(data = {}) {
    this.id = data.accountId || null;
    this.username = data.username || "";
    this.firstName = data.firstName || "";
    this.lastName = data.lastName || "";
    this.country = data.contact ? data.contact.country : "";
    this.callingCode = data.contact ? data.contact.callingCode : "";
    this.phoneNumber = data.contact ? data.contact.phoneNumber : "";
    this.city = data.contact ? data.contact.city : "";
    this.postalCode = data.contact ? data.contact.postalCode : "";
    this.address = data.contact ? data.contact.address : "";
    this.organization = data.contact ? data.contact.organization : "";
    this.image = new Image(data?.image);
    this.imageUrl = data.imageUrl || "";
    this.licenseNumber = data.licenseNumber || "";
    this.jobType = data.jobType || "";
    this.showPhone = data.contact ? data.contact.showPhone : false;
    this.showEmail = data.contact ? data.contact.showEmail : false;
  }
}

class FriendRequest {
  constructor(data = {}) {
    this.id = data.id || null;
    this.status = data.status || "";
    this.accountSender = new EventUser(data.accountSender);
  }
}
class AdminUser {
  constructor(data = {}) {
    this.email = data.email || '';
    this.username = data.username || '';
    this.password = data.password || '';
    this.role = 'admin';
    this.created = new Date();
  }
}

export { User, Account, Contact, EventUser, AccountSearch, FriendRequest, AdminUser, EditAccount };

