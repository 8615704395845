import { apiService } from "./apiService";
import Message from "./Models/Message.entity";
import Room from "./Models/Room.entity";

export const getRooms = async () => {
  const response = await apiService.get('app/chat/rooms');
  if (response.hasError) return response;

  return response.data.items.map(room => new Room(room));
}

export const getRoomMessages = async (id) => {
  const response = await apiService.get(`app/chat/messages/${id}`);
  if (response.hasError) return response;

  return response.data.items.map(message => new Message(message));
}

export const getSocketInformation = async (id) => {
  const response = await apiService.get(`app/chat/socket/${id}`);
  if (response.hasError) return response;

  return {socketUrl: response.data.socketUrl, hash: response.data.hash};
}

export const getUnreadMessage = async () => {
  const response = await apiService.get('app/chat/unread/notification');
  if (response.hasError) return response;
  return response.data;
}