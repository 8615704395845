class Document {
  constructor(data = {}) {
    this.id = data.id || null;
    this.type = data.type || "";
    this.base = data.base || "";
    this.documentUrl = data.documentUrl || "";
  }
}

export { Document };
