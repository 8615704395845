import { apiService } from "./apiService";
import { Event, EventForCalendar, SingleEvent } from "./Models/Event.entity";
import { Paragraph } from "./Models/Paragraph.entity";
import { Select } from "./Models/Select.entity";

export const createEvent = async payload => {
  const response = await apiService.post("/admin/event", payload);
  return response;
};

export const deleteEvent = async id => {
  const response = await apiService.delete(`/admin/event/${id}`);
  return response;
};

export const getAllAdminEvents = async ({ page, perPage, search }) => {
  const response = await apiService.get(
    `/admin/event?page=${page}&limit=${perPage}&search=${search}`
  );
  if (response.hasError) return response;
  const { items, count } = response.data;
  return { items: items.map(event => new Event(event)), count: count };
};

export const getAllEvents = async () => {
  const response = await apiService.get("/admin/event");
  if (response.hasError) return response;
  return response.data.items.map(event => new Select(event));
};

export const updateEvent = async (payload, id) => {
  const response = await apiService.patch(`/admin/event/${id}`, payload);
  return response;
};

export const getEventsForCalendar = async dateQuery => {
  const response = await apiService.get(`/app/event/all?date=${dateQuery}`);
  if (response.hasError) return response;
  return response.data.map(event => new EventForCalendar(event));
};

export const deleteEventParagraph = async paraId => {
  const response = await apiService.delete(`/admin/event/paragraph/${paraId}`);
  return response;
};

export const createEventParagraph = async (payload, id) => {
  const response = await apiService.post(
    `/admin/event/${id}/paragraph`,
    payload
  );
  if (response.hasError) return response;
  return new Paragraph(response.data);
};

export const updateEventParagraph = async (payload, paraId) => {
  const response = await apiService.patch(
    `/admin/event/paragraph/${paraId}`,
    payload
  );
  if (response.hasError) return response;
  return new Paragraph(response.data);
};

export const getUserEvent = async id => {
  const response = await apiService.get(`/app/event/${id}`);
  if (response.hasError) return response;
  return new SingleEvent(response.data);
};

export const getSingleEvent = async id => {
  const response = await apiService.get(`/admin/event/${id}`);
  if (response.hasError) return response;
  return new Event(response.data);
};
